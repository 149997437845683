import React from 'react';
import { PageProps } from 'gatsby';
import CardComponent from 'components/cardComponent';
import ComResFooter from 'components/comResFooter';
import Building from 'images/commercial-1.jpg';
import CommercialSpace from 'images/commercial-space.jpg';
import Office from 'images/office-space-2.jpg';

import SEO from '../components/seo';
import Layout from '../components/layout';

const CommercialHeader = () => {
  return (
    <div className='uk-flex uk-text-center uk-flex-middle uk-flex-center uk-section uk-section-regular'>
      <div className='uk-width-1-1'>
        <h1 className='uk-heading-small'>
          <mark className='mark'>Commercial Maintenance</mark>
        </h1>
        <div className='uk-text-lead'>
          The condition of your workplace says a lot about the{' '}
          <strong>condition of the company.</strong>
        </div>
      </div>
    </div>
  );
};

const Commercial = () => {
  return (
    <Layout>
      <SEO title='Commercial' />
      <div className='uk-position-relative uk-container'>
        <CommercialHeader />
        <CardComponent
          image={Building}
          heading='Building'
          subHeading='Make your commercial building '
          subHeadingStrong='inviting and exciting.'
          listItems={[
            'Lobby Repair & Rennovation',
            'Lighting & Electric',
            'Equipment Hookup',
            'Power Washing',
            'Roof & Siding',
            'Sidewalk & Walkways',
            'Heating & Air Conditioning',
            'Doors',
          ]}
        />
        <CardComponent
          image={CommercialSpace}
          heading='Commercial Spaces'
          subHeading='Show pride '
          subHeadingStrong='in your business.'
          listItems={[
            'Store Fronts',
            'Signs',
            'Lighting and Electric',
            'Roll Down Doors',
            'Display Fixtures',
            'Equipment Hookups',
            'Flooring, Ceiling & Walls',
            'Heating and Air Condition',
          ]}
        />
        <CardComponent
          image={Office}
          heading='Office'
          subHeading='Have a workplace '
          subHeadingStrong='that inspires.'
          listItems={[
            'All Repairs & Renovation',
            'Lighting & Electric',
            'Heating & Air Conditioning',
            'Doors',
            'Bathroom & Kitchen',
            'Flooring, Ceiling & Walls',
            'Equipment Hookups',
          ]}
        />
      </div>
      <ComResFooter />
    </Layout>
  );
};

export default Commercial;
